import React from "react"

import Layout from "../components/layout"

class Contact extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = 'Datenschutzerklärung'

    return (
      <Layout location={this.props.location} title={siteTitle}>
          <h1 className="text-4xl">Kontakt</h1>
          <p>Ich freue mich immer über Feedback, Kooperationsanfragen und Antworten auf meine Blog-Beiträge</p>
          <p>Bitte senden Sie mir dafür eine E-Mail an <a className="text-blue-500" href="mailto:de95wi@googlemail.com">de95wi@googlemail.com</a></p>
      </Layout>
    )
  }
}
export default Contact 